import { axiosCore } from '@/plugins/axios';
import { errorAlert } from '@/ort-lib/utils/utils';

const state = {
  valuations: [],
  isValuationsLoading: false,
  startupEvents: [],
  isEventsLoading: false,
};

const getters = {
  getValuations: (state) => state.valuations,
  getIsValuationsLoading: (state) => state.isValuationsLoading,
  getEvents: (state) => state.startupEvents,
  getIsLoading: (state) => state.isEventsLoading,
};

const actions = {
  async fetchValuations({ commit, dispatch }) {
    const url = 'events/valuations';
    commit('setIsValuationsLoading', true);
    let response;
    try {
      response = await axiosCore.get(url);
      commit('setValuations', response.data);
    } catch (error) {
      errorAlert('Failed to fetch valuations', error);
    } finally {
      commit('setIsValuationsLoading', false);
      return response;
    }
  },

  async fetchEvents({ commit, dispatch }, searchParam) {
    const url = `events/startup?${searchParam}`;
    commit('setIsEventsLoading', true);
    let response;
    try {
      response = await axiosCore.get(url);
      commit('setStartupEvents', response.data);
    } catch (error) {
      errorAlert('Failed to fetch events', error);
    } finally {
      commit('setIsEventsLoading', false);
      return response;
    }
  },

  async createEvent({ commit, dispatch }, payload) {
    const url = 'events/startup';
    commit('setIsEventsLoading', true);
    try {
      await axiosCore.post(url, payload);
    } catch (error) {
      errorAlert('Failed to create event', error);
    } finally {
      commit('setIsEventsLoading', false);
    }
  },

  async fetchEvent({ commit, dispatch }, eventId) {
    const url = `events/startup/${eventId}`;
    commit('setIsEventsLoading', true);
    let response;
    try {
      response = await axiosCore.get(url);
      commit('setStartupEvents', response.data);
    } catch (error) {
      errorAlert('Failed to fetch event', error);
    } finally {
      commit('setIsEventsLoading', false);
      return response;
    }
  },

  async updateEvent({ commit, dispatch }, { eventId, payload }) {
    const url = `events/startup/${eventId}`;
    commit('setIsEventsLoading', true);
    try {
      await axiosCore.patch(url, payload);
    } catch (error) {
      errorAlert('Failed to update event', error);
    } finally {
      commit('setIsEventsLoading', false);
    }
  },

  async deleteEvent({ commit, dispatch }, eventId) {
    const url = `events/startup/${eventId}`;
    commit('setIsEventsLoading', true);
    try {
      await axiosCore.delete(url);
    } catch (error) {
      errorAlert('Failed to delete event', error);
    } finally {
      commit('setIsEventsLoading', false);
    }
  },

};

const mutations = {
  setValuations: (state, data) => { state.valuations = data; },
  setIsValuationsLoading: (state, data) => { state.isValuationsLoading = data; },
  setStartupEvents: (state, data) => { state.startupEvents = data; },
  setIsEventsLoading: (state, data) => { state.isEventsLoading = data; },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
