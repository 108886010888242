<template>
  <aside>
   <div class="main-bar h-100">
      <MainSidebarMenu 
        @activeIcon="changeTeam"
        @closeSidebar="closeSidebar"
      />
   </div>
   <div class="sub-bar">
      <SubSidebarMenu
        :activeSection="activeSection"
        @closeSidebar="closeSidebar"
      />
   </div>
  </aside>
</template>
<script>
import MainSidebarMenu from './SidebarComponents/MainSidebarMenu.vue'
import SubSidebarMenu from './SidebarComponents/SubSidebarMenu.vue';

export default {
  components: {
    MainSidebarMenu,
    SubSidebarMenu,
  },
  data() {
    return {
      activeSection: '',
    }
  },
  methods: {
    changeTeam(icon) {
      this.activeSection = icon;
    },
    closeSidebar() {
      this.$emit('closeSidebar');
    }
  },
}

</script>
<style lang="scss" scoped>
  aside {
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 12;
    overflow-x: clip;
    border-right: 1px solid $grey-600;
    display: flex;
    gap: 12px;

    .sub-bar {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
</style>