<template>
  <div class="m-5">
    <p class="text-xl--semibold">
      Upload a report for {{ startupName }}
    </p>
    <FormFileUploadWrapper
      model="reportings"
      :modelPK="reportId"
      modelAttribute="report_document"
      :useFileName="true"
      @postFile="enableButtons"
      @getFile="enableButtons"
    />

    <ORTMessage
      class="mt-5"
      title="Publishing the report"
      text="By default, the report is hidden to every investor. To make it visible, click on a button below."
    />
    <div class="d-flex">
      <ORTTooltip
        class="mr-3"
        text="The report will be visible to the investors in the Platform"
      >
        <ORTButton
          class="mt-4"
          @click="publishReport"
          variant="secondary"
          text="Publish (no email)"
          :disabled="isButtonsDisabled"
          width="100%"
        />
      </ORTTooltip>
      <ORTTooltip
        text="The report will be visible to the investors in the Platform, and they will get notified by email"
      >
        <ORTButton
          class="mt-4"
          @click="sendReport"
          text="Publish & Send email"
          :disabled="isButtonsDisabled"
          width="100%"
        />
      </ORTTooltip>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ORTButton from '@/ort-lib/components/ORTButton.vue';
import ORTMessage from '@/components/ort-lib/ORTMessage.vue';
import FormFileUploadWrapper from '@/components/ort-lib/FormFileUpload/FormFileUploadWrapper.vue';
import ORTTooltip from '@/ort-lib/components/ORTTooltip.vue';
import { axiosCore } from '@/plugins/axios';
import { errorAlert, successAlert } from '@/ort-lib/utils/utils';

export default {
  name: 'AddReport',
  components: {
    ORTButton,
    ORTMessage,
    ORTTooltip,
    FormFileUploadWrapper,
  },
  props: {
    reportId: {
      type: Number,
      required: true,
    },
    startupName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isButtonsDisabled: true,
    };
  },
  methods: {
    ...mapActions(['addNewReport']),
    async sendReport() {
      try {
        await axiosCore.post(`/startups/reportings/${this.reportId}/send?email=true`);
        successAlert('Report sent correctly');
      } catch (err) {
        errorAlert('Could not send report email', err);
      }
      this.$emit('close');
    },
    async publishReport() {
      try {
        await axiosCore.post(`/startups/reportings/${this.reportId}/send?email=false`);
        successAlert('Report published correctly');
      } catch (err) {
        errorAlert('Could not publish report', err);
      }
      this.$emit('close');
    },
    enableButtons(document) {
      if (document) {
        this.isButtonsDisabled = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.warning-message) {
  margin-bottom: 0;;
}
</style>
