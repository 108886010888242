import { axiosCore } from '@/plugins/axios';

const Login = () => import('@/components/Pages/Login/Login.vue');
const ProfileSettings = () => import('@/components/Pages/ProfileSettings/ProfileSettings.vue');
const Dashboard = () => import('@/components/Pages/Dashboard/Dashboard.vue');
const Investors = () => import('@/components/Pages/Investors/Investors.vue');
const Investor = () => import('@/components/Pages/Investor/Investor.vue');
const Partners = () => import('@/components/Pages/Partners/Partners.vue');
const Partner = () => import('@/components/Pages/Partner/Partner.vue');
const Investments = () => import('@/components/Pages/Investments/Investments.vue');
const Investment = () => import('@/components/Pages/Investment/Investment.vue');
const Startups = () => import('@/components/Pages/Startups/Startups.vue');
const NewStartupsTable = () => import('@/components/Pages/Startups/NewStartupsTable.vue');
const Startup = () => import('@/components/Pages/Startup/Startup.vue');
const Fundraisings = () => import('@/components/Pages/Fundraisings/Fundraisings.vue');
const Valuations = () => import('@/components/Pages/Valuations/Valuations.vue');
const Fundraising = () => import('@/components/Pages/Fundraising/Fundraising.vue');
const Fund = () => import('@/components/Pages/Fundraising/Fund.vue');
// const FinancesOld = () => import('@/components/Pages/Finances/FinancesOld.vue');  // TODO delete files
const Bills = () => import('@/components/Pages/Finances/sections/DashboardBills.vue');
const Payouts = () => import('@/components/Pages/Finances/Payouts.vue');
const Users = () => import('@/components/Pages/CustomUsers/Users.vue');
const CustomUser = () => import('@/components/Pages/CustomUser/CustomUser.vue');
const ClientsDashboard = () => import('@/components/Pages/Clients/ClientsDashboard.vue');
const StartupsDashboard = () => import('@/components/Pages/Startups/StartupsDashboard.vue');
const DashboardDealflow = () => import('@/components/Pages/Dashboard/DashboardDealflow/DashboardDealflow.vue');
const Files = () => import('@/components/Pages/Dashboard/DashboardFiles/DashboardFiles.vue');
const DashboardTopBa = () => import('@/components/Pages/Dashboard/DashboardTopBa/DashboardTopBa.vue');
const DashboardTopBaExp = () => import('@/components/Pages/Dashboard/DashboardTopBa/DashboardTopBaExp.vue');


const routes = [
  {
    path: '/:patchMatch(.*)',
    redirect: { name: '404' },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
// redirects
  {
    path: '/',
    redirect: '/Dashboards/finance-dashboard',
  },
  {
    name: 'Investments',
    path: '/investments',
    redirect: '/Finance/investments',
  },
  {
    name: 'Investment',
    path: '/investments/:id',
    redirect: to => {
      return `/Finance/investments/${to.params.id}`;
    },
  },
  {
    name: 'Fundraisings',
    path: '/fundraisings',
    redirect: '/Finance/fundraisings',
  },
  {
    name: 'Fundraising',
    path: '/fundraisings/:id',
    redirect: to => { return `/Finance/fundraisings/${to.params.id}`; },
  },
  {
    path: '/investors',
    redirect: '/Investors/investors',
  },
  {
    path: '/investors/:id',
    redirect: to => { return `/Investors/investors/${to.params.id}`; },
  },
  {
    path: '/partners',
    redirect: '/Investors/partners',
  },
  {
    path: '/partners/:id',
    redirect: to => { return `/Investors/partners/${to.params.id}`; },
  },
  {
    path: '/users',
    redirect: '/Investors/users',
  },
  {
    path: '/users/:id',
    redirect: to => { return `/Investors/users/${to.params.id}`; },
  },
  {
    path: '/bills',
    redirect: '/Finance/bills',
  },
  {
    path: '/valuations',
    redirect: '/Finance/valuations',
  },
  {
    path: '/settings',
    redirect: '/Settings/settings',
  },
  {
    path: '/tools',
    redirect: '/Dealflow/ba-experiences',
  },

//Dashboard section
  {
    path: '/Dashboards/finance-dashboard',
    name: 'Finance',
    component: Dashboard,
  },
  {
    path: '/Dashboards/dashboard-investors',
    name: 'Dashboard-investors',
    component: ClientsDashboard,
  },
  {
    path: '/Dashboards/dealflow',
    name: 'Dealflow',
    component: DashboardDealflow,
  },

// Finance section
  {
    path: '/Finance/bills',
    name: 'Bills',
    component: Bills,
  },
  {
    path: '/Finance/payouts',
    name: 'Payouts',
    component: Payouts,
  },
  {
    path: '/Finance/valuations',
    name: 'Valuations',
    component: Valuations,
  },
  {
    path: '/Finance/investments',
    name: 'Finance-Investments',
    component: Investments,
  },
  {
    path: '/Finance/investments/:id',
    name: 'Finance-Investment',
    component: Investment,
  },
  {
    path: '/Finance/fundraisings',
    name: 'Finance-Fundraisings',
    component: Fundraisings,
  },
  {
    path: '/Finance/fundraisings/:id',
    name: 'Finance-Fundraising',
    component: Fundraising,
  },
  {
    path: '/Finance/fund/:id',
    name: 'Fund',
    component: Fund,
  },


  // Investors section
  {
    path: '/Investors/users',
    name: 'Users',
    component: Users,
  },
  {
    path: '/Investors/users/:id',
    name: 'CustomUser',
    component: CustomUser,
  },
  {
    path: '/Investors/investors',
    name: 'Investors',
    component: Investors,
  },
  {
    path: '/Investors/investors/:id',
    name: 'Investor',
    component: Investor,
  },
  {
    path: '/Investors/partners',
    name: 'Partners',
    component: Partners,
  },
  {
    path: '/Investors/partners/:id',
    name: 'Partner',
    component: Partner,
  },
  {
    path: '/Investors/investments',
    name: 'Investors-Investments',
    component: Investments,
  },
  {
    path: '/Investors/investments/:id',
    name: 'Investors-Investment',
    component: Investment,
  },
  {
    path: '/Investors/teasers',
    name: 'Teasers',
    component: Files,
  },


  //Dealflow section
  {
    path: '/Dealflow/startups',
    name: 'Startups',
    component: Startups,
  },
  {
    path: '/Dealflow/new-startups',
    name: 'NewStartups',
    component: NewStartupsTable,
  },
  {
    path: '/Dealflow/startups/:id',
    name: 'Startup',
    component: Startup,
  },
  {
    path: '/startups/:id',
    redirect: to => { return `/Dealflow/startups/${to.params.id}`; },
  },
  {
    path: '/Dealflow/fundraisings',
    name: 'Dealflow-Fundraisings',
    component: Fundraisings,
  },
  {
    path: '/Dealflow/fundraisings/:id',
    name: 'Dealflow-Fundraising',
    component: Fundraising,
  },
  {
    path: '/Dealflow/ba-experiences',
    name: 'BAExperiences',
    component: DashboardTopBaExp,
  },
  {
    path: '/Dealflow/ba-profiles',
    name: 'BAProfiles',
    component: DashboardTopBa,
  },
  {
    path: '/startups-dashboard',
    name: 'StartupsDashboard',
    component: StartupsDashboard,
  },
  {
    path: '/Settings/settings',
    name: 'ProfileSettings',
    component: ProfileSettings,
  },
];

export default routes;
