import { axiosCore } from '@/plugins/axios';

const state = {
  userLogins: [],
  investorInterests: [],
  userLoginsCount: 0,
  investorInterestsCount: 0,
  allUserLogins: {},
  allUserLoginsCount: 0,
  allInvestorLogins: {},
};

const getters = {
  getUserLogins: (state) => state.userLogins,
  getInvestorInterests: (state) => state.investorInterests,
  getUserLoginsCount: (state) => state.userLoginsCount,
  getInvestorInterestsCount: (state) => state.investorInterestsCount,
  getAllUserLogins: (state) => state.allUserLogins,
  getAllInvestorLogins: (state) => state.allInvestorLogins,

};
const mutations = {
  setUserLogins: (state, userLogins) => (state.userLogins = userLogins),
  setInvestorInterests: (state, investorInterests) => (state.investorInterests = investorInterests),
  setUserLoginsCount: (state, userLoginsCount) => (state.userLoginsCount = userLoginsCount),
  setInvestorInterestsCount: (state, investorInterestsCount) => (state.investorInterestsCount = investorInterestsCount),
  setAllUserLogins: (state, allUserLogins) => (state.allUserLogins = allUserLogins),
  setAllInvestorLogins: (state, allInvestorLogins) => (state.allInvestorLogins = allInvestorLogins),
};

const actions = {
  async fetchUserLogins({ commit, dispatch }, {
    perPage, page, searchParam, selectedFilters, order,
  }) {
    try {
      const response = await axiosCore.get(`/user_login/?${searchParam}page=${page}&page_size=${perPage}${selectedFilters}${order}`);
      commit('setUserLogins', response.data.results);
      commit('setUserLoginsCount', response.data.count);
    } catch (error) {
      dispatch('triggerAlert', {
        show: true,
        type: 'danger',
        message: error.message,
      });
    }
  },
  async fetchAllUserLogins({ commit }, mode) {
    try {
      const mode_param = mode || 'month';
      const response = await axiosCore.get(`/dashboard/user-logins?mode=${mode_param}`);
      commit('setAllUserLogins', response.data);
    } catch (error) {
    }
  },
  async fetchAllInvestorLogins({ commit }, mode) {
    try {
      const mode_param = mode || 'month';
      const response = await axiosCore.get(`/dashboard/investor-logins?mode=${mode_param}`);
      commit('setAllInvestorLogins', response.data);
    } catch (error) {
    }
  },
  async fetchInvestorInterests({ commit, dispatch }, {
    perPage, page, searchParam, selectedFilters, order,
  }) {
    try {
      const response = await axiosCore.get(`/investor_interests/?${searchParam}page=${page}&page_size=${perPage}${selectedFilters}${order}`);
      commit('setInvestorInterests', response.data.results);
      commit('setInvestorInterestsCount', response.data.count);
    } catch (error) {
      dispatch('triggerAlert', {
        show: true,
        type: 'danger',
        message: error.message,
      });
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
