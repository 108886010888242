<template>
  <FormFileUpload
    :label="label"
    :tooltipMessage="tooltipMessage"
    :loading="loading"
    :deleting="deleting"
    :document="document"
    :status="status"
    :disabled="disabled"
    :uuid="uuid"
    :noDelete="noDelete"
    @postFile="postFile"
    @deleteFile="deleteFile"
    @startDownloading="startDownloading"
    @errorDownloading="errorDownloading"
  >
    <slot></slot>
  </FormFileUpload>
</template>

<script>
import { v4 } from 'uuid';
import { mapActions } from 'vuex';
import FormFileUpload from './FormFileUpload.vue';

export default {
  name: 'FormFileUploadWrapper',
  components: {
    FormFileUpload,
  },
  props: {
    label: {
      type: String,
      required: false,
    },
    tooltipMessage: {
      type: String,
      required: false,
    },
    model: {
      type: String,
      required: true,
    },
    modelPK: {
      type: [String, Number],
      required: true,
    },
    modelAttribute: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
    noDelete: {
      type: Boolean,
      required: false,
    },
    useFileName: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      deleting: false,
      document: null,
    };
  },
  computed: {
    documentData() {
      return {
        model: this.model,
        id: this.modelPK,
        attribute: this.modelAttribute,
        url: this.documentURL,
      };
    },
    documentURL() {
      return `/files/document/staff/${this.model}/${this.modelPK}/${this.modelAttribute}/`;
    },
    uuid() {
      return this.id || v4();
    },
  },
  methods: {
    ...mapActions(['getDocumentSilently', 'deleteDocument', 'uploadFile']),
    getFile() {
      this.loading = true;
      this.getDocumentSilently(this.documentData)
        .then((response) => { this.document = response.data; })
        .finally(() => {
          this.loading = false;
          this.$emit('getFile', this.document, this.modelAttribute);
        });
    },
    postFile(fileContent, fileName) {
      this.loading = true;
      const formData = new FormData();
      formData.append('file_obj', fileContent);
      if (this.useFileName) formData.append('name', fileName);
      this.documentData.post_data = formData;
      this.uploadFile(this.documentData)
        .then((response) => { this.document = response.data; })
        .finally(() => {
          this.finishLoading();
          this.$emit('postFile', this.document, this.modelAttribute);
        });
    },
    deleteFile() {
      this.deleting = true;
      this.deleteDocument(this.documentData)
        .then((response) => { this.document = response.data; })
        .finally(() => {
          this.finishLoading();
          this.$emit('deleteFile', this.document, this.modelAttribute);
        });
    },
    finishLoading() {
      this.loading = false;
      this.deleting = false;
    },
    startDownloading() {
      this.$store.dispatch('triggerAlert', {
        show: true,
        type: 'success',
        message: 'File download started, it can take some seconds, please wait...',
      });
    },
    errorDownloading() {
      this.$store.dispatch('triggerAlert', {
        show: true,
        type: 'danger',
        message: 'File download failed, please try again.',
      });
    },
  },
  created() {
    this.getFile();
  },
};
</script>

<style lang="scss" scoped>

</style>
