<template>
  <svg
    class="loading"
    :width="spinnerSize"
    :height="spinnerSize"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path :d="backgroundSVG" :fill="backgroundColorComputed"/>
    <path :d="spinnerSVG" :fill="spinnerColorComputed"/>
  </svg>
</template>

<script>

export default {
  name: 'ORTSpinningLoader',
  props: {
    backgroundColor: {
      type: String,
      required: false,
      default: '#FDF0EF',
    },
    spinnerColor: {
      type: String,
      required: false,
      default: '#EF6861',
    },
    size: {
      type: String,
      required: false,
      default: 'xs',
    },
  },
  data() {
    return {
      themeSizes: {
        xs: '16px',
        sm: '20px',
        md: '24px',
        xl: '30px',
        xxl: '50px',
      },
    };
  },
  computed: {
    spinnerSize() {
      return this.themeSizes[this.size] || this.size;
    },
    backgroundColorComputed() {
      return this.backgroundColor;
    },
    spinnerColorComputed() {
      return this.spinnerColor;
    },
    backgroundSVG() {
      return 'M20.3348 10.0004C20.3348 15.5234 15.8574 20.0008 10.3344 20.0008C4.81131 20.0008 '
      + '0.333984 15.5234 0.333984 10.0004C0.333984 4.47733 4.81131 0 10.3344 0C15.8574 0 20.3348 '
      + '4.47733 20.3348 10.0004ZM2.33406 10.0004C2.33406 14.4188 5.91592 18.0007 10.3344 '
      + '18.0007C14.7528 18.0007 18.3347 14.4188 18.3347 10.0004C18.3347 5.58194 14.7528 2.00008 '
      + '10.3344 2.00008C5.91592 2.00008 2.33406 5.58194 2.33406 10.0004Z';
    },
    spinnerSVG() {
      return 'M10.3344 1.00004C10.3344 0.447733 10.7832 -0.00518342 11.3328 0.0499526C13.0894 '
      + '0.226196 14.7746 0.865219 16.2125 1.9099C17.6503 2.95459 18.7788 4.3598 19.4893 '
      + '5.976C19.7115 6.48162 19.4195 7.04846 18.8942 7.21913V7.21913C18.3689 7.3898 17.8104 '
      + '7.09869 17.5755 6.59879C17.0041 5.38236 16.1328 4.32427 15.0368 3.528C13.9409 2.73173 '
      + '12.6653 2.23006 11.3318 2.06249C10.7838 1.99363 10.3344 1.55234 10.3344 1.00004V1.00004Z';
    },
  },
};
</script>

<style lang="scss" scoped>

.loading {
  animation: spin 1s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
