/**
 * JSDoc is a markup language used to annotate JavaScript source code files.
 * It is used to add information about the code, eg what it does, what parameters it takes, what it returns.
 * JSDoc is a lighter-weight alternative to TypeScript and does not force you to use types.
 * We are adopting JSDoc in our heavily-used stores to make it easier to use in multiple components.
 * This lets us know what each store method/property takes in and returns.
 *
 * For more info on how we use JSDoc see this: https://www.notion.so/oneragtime/a56830e220b6466dac5d9892610bcb7e
 *
 * The official documentation of JSDoc can be found here: https://jsdoc.app/
*/

/** @typedef {import("@/ort-lib/stores/accounts/jwt").Getters} JWTGetters */
/** @typedef {import("@/ort-lib/stores/accounts/jwt").Actions} JWTActions */
/** @typedef {import("@/ort-lib/types/accounts/users.js").User} User */
/** @typedef {import("@/ort-lib/types/accounts/users.js").SearchedUser} SearchedUser */
/** @template T; @typedef {import("src/types/utils.js").PaginatedResults<T>} PaginatedResults<T> */
/** @typedef {User["contact_info"] ContactInfo} */
/** @typedef {User["basic_info"] BasicInfo} */

import { axiosCore } from '@/plugins/axios';
import { isEmpty } from '@/ort-lib/utils/validators';
import { getGetter, getAction } from '@/ort-lib/utils/jsdoc.js';
import { errorAlert, successAlert } from '@/ort-lib/utils/utils';

/**
 * @typedef {{
 *  forceFetchUser: boolean;
 *  currentUserId: number;
 *  userData: Object.<number, User>;
 * }} State
*/

/** @type {State} */
const state = {
  forceFetchUser: false,
  currentUserId: 0,
  userData: {
    0: {
      contact_info: {},
      basic_info: {},
    },
  },
};

const getters = {
  /** @param {State} state */
  getCurrentUserId: (state) => state.currentUserId,
  /** @param {State} state */
  allFetchedUserData: (state) => state.userData,
  /** @param {State} state */
  getUser: (state) => state.userData[state.currentUserId],
  /** @param {State} state */
  getContactInfo: (state) => state.userData[state.currentUserId].contact_info,
  /** @param {State} state */
  getBasicInfo: (state) => state.userData[state.currentUserId].basic_info,
};

const actions = {
  /**
   * Get "contact info" of the user.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {number} userId - The ID of the user.
  */
  async getContactInfo({ commit }, userId) {
    try {
      if (isEmpty(state.userData[userId]?.contact_info) || state.forceFetchUser) {
        /** @type {{ data: ContactInfo }} */
        const response = await axiosCore.get(`/auth/users/${userId}/contact-info-full`);
        commit('setUserData', { userId, userData: { contact_info: response.data } });
        commit('setForceFetchUser', false);
      }
      commit('setCurrentUserId', userId);
      return state.userData[userId].contact_info;
    } catch (error) {
      errorAlert('Failed to fetch user contact info', error);
    }
  },
  /**
   * Get "Basic Info" of the user.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {number} userId - The ID of the user.
  */
  async getUserBasicInfo({ commit }, userId) {
    try {
      if (isEmpty(state.userData[userId]?.basic_info) || state.forceFetchUser) {
        /** @type {{ data: BasicInfo }} */
        const response = await axiosCore.get(`/auth/users/${userId}/basic-info`);
        commit('setUserData', { userId, userData: { basic_info: response.data } });
        commit('setForceFetchUser', false);
      }
      commit('setCurrentUserId', userId);
      return state.userData[userId].basic_info;
    } catch (error) {
      errorAlert('Failed to fetch user basic info', error);
    }
  },
  /**
   * Update "Basic Info" of the user.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{
   *  userId: number;
   *  first_name?: string;
   *  last_name?: string;
   *  phone_number?: string;
   *  email?: string;
   *  onboarded_on?: string;
   *  is_active?: boolean;
   *  preferred_language?: string;
   *  beta_status?: string;
   * }} payload - The user's data.
  */
  async updateUserBasicInfo({ dispatch, commit }, { userId, ...payload }) {
    try {
      /** @type {{ data: BasicInfo }} */
      const response = await axiosCore.patch(`/auth/users/${userId}/basic-info`, payload);
      successAlert('User updated successfully!');
      commit('setForceFetchUser', true);
      await dispatch('getUserBasicInfo', userId);
      commit('setCurrentUserId', userId);

      // If the user is updating their own info, refresh the tokens (language is in token claims)
      const currentUserId = getGetter(/** @type {JWTGetters["getUserId"]} */ ('getUserId'));
      if (userId == currentUserId) {
        const hardRefreshTokens = getAction(/** @type {JWTActions["hardRefreshTokens"]} */ ('hardRefreshTokens'));
        await hardRefreshTokens();
      }
      return response.data;
    } catch (error) {
      errorAlert('Failed to update user basic info', error);
    }
  },
  /**
   * Changes the password of the user.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{
   *  userId: number;
   *  old_password: string;
   *  new_password: string;
   * }} payload
   * @returns {Promise<boolean>}
  */
  async changeUserPassword({ commit }, { userId, old_password, new_password }) {
    try {
      await axiosCore.patch(`/auth/users/${userId}/change-password`, {
        old_password, new_password,
      });
      commit('setCurrentUserId', userId);
      return successAlert('Password updated successfully!');
    } catch (error) {
      return errorAlert('Failed to change password', error);
    }
  },
  /**
   * Switch active entity for User
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{
   *  userId: number;
   *  entity_type: string;
   *  relationship_id: number;
   * }} payload
   * @returns {Promise<boolean>}
  */
  async setActiveEntity({ dispatch }, { userId, relationship_id, entity_type }) {
    try {
      await axiosCore.patch(`/auth/users/${userId}/active-profile`, {entity_type, relationship_id});
      return successAlert('Entity switched successfully');
    } catch (error) {
      return errorAlert('Failed to set active entity', error);
    }
  },
  /**
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{
   *  searchTerm: number;
   *  page?: number;
   *  page_size?: number;
   * }} params - The search parameters.
  */
  async searchUser({ commit }, { searchTerm, page = 1, page_size = 10 }) {
    /** @type {{ data: PaginatedResults<SearchedUser> }} */
    const response = await axiosCore.get(`/users/list-core?search=${searchTerm}&page=${page}&page_size=${page_size}`);
    return response.data;
  },
};

const mutations = {
  /**
   * The user id to associate with the getters.
   * @param {State} state - Implicit parameter for vuex mutations.
   * @param {number} userId - ID of the user.
  */
  setCurrentUserId: (state, userId) => {
    if (!isEmpty(state.userData[userId])) state.currentUserId = userId;
  },
  /**
   * Updates the user data using supplied fields for the given user ID.
   * @param {State} state - Implicit parameter for vuex mutations.
   * @param {number} userId - ID of the user.
   * @param {User} userData - Data to set for the user.
  */
  setUserData: (state, { userId, userData }) => {
    state.userData[userId] = { ...state.userData[userId], ...userData };
  },
  /**
   * Flag which defines if cached data should be refetched or not.
   * @param {State} state - Implicit parameter for vuex mutations.
   * @param {boolean} fetch - Whether to force fetch or not.
  */
  setForceFetchUser: (state, fetch) => {
    state.forceFetchUser = fetch;
  },
};

/** @typedef {typeof getters} Getters */
/** @typedef {typeof actions} Actions */
/** @typedef {typeof mutations} Mutations */

/**
 * @module userInfo
 * @typedef {Object} UserInfoStore
 * @property {State} state
 * @property {Getters} getters
 * @property {Actions} actions
 * @property {Mutations} mutations
 */

export default {
  state,
  getters,
  actions,
  mutations,
};
