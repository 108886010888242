<template>
  <div>

    <!-- HEADER -->
    <div class="header" v-if="label">
      <ORTTooltip
        v-if="tooltipMessage"
        :text="tooltipMessage"
        :uuid="uuid"
        width="150px"
        triangleLeft="40%"
        class="mr-2"
      >
        <InfoCircleIcon/>
      </ORTTooltip>
      <span class="header-label text-sm--semibold">{{ label }}</span>
      <ORTBadge v-if="Boolean(status)" :text="status" :color="status"/>
    </div>

    <!-- CONTAINER / BUTTON -->
    <div class="d-flex">
      <div
        :class="buttonClass"
        @click="!hasDocument && !disabled ? $refs.file.click() : null"
      >

        <!-- LOADER -->
        <div v-if="loading || deleting" class="upload-loading">
          <ORTSpinningLoader/>
          <span class="upload-button-label ml-2"> {{`${loading ? 'Upload' : 'Deleting'} file`}} </span>
        </div>

        <!-- UPLOAD BUTTON -->
        <div v-else-if="!hasDocument" class="upload-button" :class="{ 'disabled': disabled }">
          <input
            type="file"
            ref="file"
            @change="handleFile"
          />
          <img :src="UploadIcon" alt="UploadIcon" class="upload-icon">
          <span class="upload-button-label"> {{ inputText }} </span>
        </div>

        <!-- DOCUMENT FULFILLED CONTAINER -->
        <div v-else class="fulfilled-container">
          <span :title="documentName" class="fulfilled-container-label"> {{documentName}} </span>
          <div class="fulfilled-buttons">
            <ORTSpinningLoader v-if="downloading" class="fulfilled-button spinner"/>
            <div v-else :class="deleteButtonClass" @click="downloadFile">
              <img :src="DownLoadIcon" alt="DownLoadIcon" class="download-icon">
            </div>
            <div v-if="!noDelete" class="fulfilled-button delete-container" @click.stop="deleteFile">
              <img :src="CrossIcon" class="x-icon" alt="CrossIcon">
            </div>
          </div>
        </div>
      </div>

      <slot></slot>
    </div>
  </div>
</template>

<script>
import ORTSpinningLoader from '@/components/ort-lib/ORTSpinningLoader.vue';
import ORTTooltip from '@/ort-lib/components/ORTTooltip.vue';
import ORTBadge from '@/ort-lib/components/ORTBadge.vue';
import InfoCircleIcon from '@/assets/icons/info-circle.vue';

export default {
  name: 'FormFileUpload',
  components: {
    ORTTooltip,
    ORTSpinningLoader,
    ORTBadge,
    InfoCircleIcon,
  },
  props: {
    label: {
      type: String,
      required: false,
    },
    tooltipMessage: {
      type: String,
      required: false,
    },
    document: {
      type: String,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    deleting: {
      type: Boolean,
      required: false,
    },
    status: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    uuid: {
      type: String,
      required: false,
    },
    isImage: {
      type: Boolean,
      default: false,
    },
    noDelete: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      UploadIcon: new URL('@/assets/icons/upload-01.svg', import.meta.url).href,
      DownLoadIcon: new URL('@/assets/icons/download-01.svg', import.meta.url).href,
      CrossIcon: new URL('@/assets/icons/x.svg', import.meta.url).href,
      file: null,
      downloading: null,
    };
  },
  computed: {
    inputText() {
      if (this.disabled) {
        return 'empty';
      } return this.isImage ? 'Upload image' : 'Upload file';
    },
    hasDocument() {
      return Boolean(this.document);
    },
    documentName() {
      return this.document.split('?')[0].split('/').slice(-1)[0];
    },
    buttonClass() {
      const baseClass = 'upload';
      const typeClass = `${this.hasDocument ? 'fulfilled' : 'clickable'}`;
      const disabledClass = `${!this.hasDocument && this.disabled ? 'disabled' : ''}`;
      return `${baseClass} ${disabledClass} ${typeClass} `;
    },
    deleteButtonClass() {
      return `fulfilled-button delete-container${this.disabled ? '-disabled' : ''}`;
    },
  },
  methods: {
    handleFile() {
      this.file = this.$refs.file.files[0];
      this.postFile(this.file);
    },
    postFile(data) {
      this.$emit('postFile', data, this.file.name.trim(), this.$refs.file.files[0]);
    },
    deleteFile() {
      if (!this.disabled) this.$emit('deleteFile');
    },
    downloadFile() {
      this.downloading = true;
      this.$emit('startDownloading');
      fetch(this.document, { method: 'get' })
        .then((response) => response.blob())
        .then((response) => { this.forceFileDownload(response); })
        .catch(() => { this.$emit('errorDownloading'); });
    },
    async forceFileDownload(response) {
      const link = document.createElement('a');
      link.setAttribute('href', window.URL.createObjectURL(new Blob([response])));
      link.setAttribute('download', this.documentName);
      document.body.appendChild(link);
      link.click();
      this.downloading = false;
    },
  },
};
</script>

<style lang="scss" scoped>

.header{
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  &-label{
    padding-right: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
}

.clickable{
  cursor: pointer;
}

.upload {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 14px;
  gap: 8px;
  width: 150px;
  height: 42px;
  background: #FFFFFF;
  border: 1px solid #D1D1D6;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 12px;
  display: flex !important;
  align-items: center !important;

  &-button{
    display: flex;
    align-items: center;

    :deep(.ort-icon) {
      margin-left: -5px;
    }
    .upload-button-label{
      @extend .text-sm--bold;
      padding-left: 8px;
    }
  }
}

.disabled{
  background: $grey-700;
  cursor: not-allowed;
}

.fulfilled {
  width: 244px;
  height: 44px;

  &-container{
    width: 100%;
    display: flex;
    padding: 10px 0;
    align-items: center;
    justify-content: space-between;

    &-label{
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &-button{
    width: 28px;
    height: 28px;
    border-radius: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 12px;

    &:last-child{
      margin-right: 0;
    }
  }

  &-buttons{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .spinner{
    width: 27px;
    height: 20px;
  }
}

.delete-container{
  &:hover{
    background-color: #FDF0EF;
    :deep() {
      span.ort-icon.delete-button{
        background-color: $danger !important;
      }
    }
  }
  &-disabled{
    cursor: not-allowed !important;
    &:hover{
      background-color: $grey-500 !important;
    }
  }
}

input {
  display: none;
}
</style>
