<template>
  <div class="wrapper">
    <div class="input-wrapper">
      <div
        v-if="searchTerm"
        class="overlay"
        :class="{ 'dark-overlay': searchTerm }"
        @click="clear"
      />
      <div class="search-create">
        <ORTInput
          class="my-input"
          v-model="searchTerm"
          @update:modelValue="throttledSearch"
          placeholder="🔍  Search"
          type="search"
          edit
        />
        <CreateEntityDropdown class="create-dropdown"/>
      </div>
      <div class="pages-btn" @click="openMobileSidebar">
        <img :src="hamburgerIcon" alt="menu icon" >
      </div>
    </div>
    <SearchBarDropdown
      v-show="searchTerm"
      @view-all="viewAll"
      @entry-clicked="entryClicked"
      :loading="toFetchCount > 0"
      :userResults="userResults"
      :investorResults="investorResults"
      :startupResults="startupResults"
      :partnerResults="partnerResults"
      :investmentResults="investmentResults"
      :fundraisingResults="fundraisingResults"
    />
  </div>
</template>
<script>
/** @typedef {import("@/ort-lib/stores/accounts/user").Actions} UserActions */
/** @typedef {import("@/ort-lib/stores/entities/startup").Actions} StartupActions */
/** @typedef {import("@/ort-lib/stores/entities/partner").Actions} PartnerActions */
/** @typedef {import("@/store/modules/entities/investors/investor").Actions} InvestorActions */

import { getAction } from '@/utils/jsdoc.js';
import { useDebounceFn } from '@vueuse/core';
import ORTInput from '@/ort-lib/components/ORTInput.vue';
import SearchBarDropdown from '@/components/utils/SearchBarDropdown.vue';
import CreateEntityDropdown from '@/components/utils/CreateEntityDropdown.vue';

export default {
  name: 'SearchBar',
  components: {
    ORTInput,
    SearchBarDropdown,
    CreateEntityDropdown,
  },
  data() {
    return {
      hamburgerIcon: new URL('@/ort-lib/assets/icons/menu-02.svg', import.meta.url).href,
      toFetchCount: 0,
      searchTerm: '',
      userResults: {},
      startupResults: {},
      partnerResults: {},
      investorResults: {},
      investmentResults: {},
      fundraisingResults: {},
      actions: {
        searchUser: getAction(/** @type {UserActions["searchUser"]} */ ('searchUser')),
        searchStartup: getAction(/** @type {StartupActions["searchStartup"]} */ ('searchStartup')),
        searchPartner: getAction(/** @type {PartnerActions["searchPartner"]} */ ('searchPartner')),
        searchInvestor: getAction(/** @type {InvestorActions["searchInvestor"]} */ ('searchInvestor')),
        searchInvestment: getAction(/** @type {InvestorActions["searchInvestment"]} */ ('searchInvestment')),
        searchFundraising: getAction(/** @type {InvestorActions["searchFundraising"]} */ ('searchFundraising')),
      },
    };
  },
  methods: {
    clear() {
      this.searchTerm = '';
      this.userResults = {};
      this.startupResults = {};
      this.partnerResults = {};
      this.investorResults = {};
      this.investmentResults = {};
      this.fundraisingResults = {};
    },
    searchFor(searchTerm) {
      this.toFetchCount = 6;
      this.searchTerm = searchTerm;
      if (searchTerm) {
        this.actions.searchUser({searchTerm, page_size: 4}).then((res) => {
          this.userResults = res;
          this.toFetchCount--;
        });
        this.actions.searchStartup({searchTerm, page_size: 4}).then((res) => {
          this.startupResults = res;
          this.toFetchCount--;
        });
        this.actions.searchPartner({searchTerm, page_size: 4}).then((res) => {
          this.partnerResults = res;
          this.toFetchCount--;
        });
        this.actions.searchInvestor({searchTerm, page_size: 4}).then((res) => {
          this.investorResults = res;
          this.toFetchCount--;
        });
        this.actions.searchInvestment({searchTerm, page_size: 4}).then((res) => {
          this.investmentResults = res;
          this.toFetchCount--;
        });
        this.actions.searchFundraising({searchTerm, page_size: 4}).then((res) => {
          this.fundraisingResults = res;
          this.toFetchCount--;
        });
      } else {
        this.clear();
      }
    },
    throttledSearch: useDebounceFn(function (searchTerm) {
      this.searchFor(searchTerm);
    }, 750),
    viewAll(type) {
      this.$router.push({ name: type, query: { search: this.searchTerm } });
      this.searchFor(this.searchTerm = '');
    },
    async entryClicked(entry, type) {
      await this.$router.push({ name: type, params: { id: entry.id } });
      this.clear();
    },
    openMobileSidebar() {
      this.$emit('openSideBar')
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  position: fixed;
  top: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100vw;
  width: 100%;
}
.input-wrapper {
  width: 100%;
  max-width: 100%;
  z-index: 5;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid $grey-600;
  @media (max-width: 991px) {
    flex-direction: row-reverse;
  }
}
.search-create {
  padding: 0 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  @media (max-width: 991px) {
    width: 100%;
  }
}
.my-input {
  width: 325px;
  z-index: 5;
  @media (max-width: 991px) {
    width: 50%;
  }
}
.create-dropdown {
  margin-top: 2px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}
.dark-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}
.pages-btn {
  cursor: pointer;
  padding: 10px 30px;
  display: none;
  @media (max-width: 991px) {
    display: block;
  }
}
</style>
