<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col px-4 pb-3">
        <div v-if="loading">
          <div class="row">
            <div class="col">
              <h4>Creating, please wait...</h4>
            </div>
          </div>
          <div class="row">
            <loading class="mx-auto mt-4" :active="loading" color="red"></loading>
          </div>
        </div>
        <ORTMessage
          v-if="!loading"
          title="No email notification will be sent"
          text="You can publish the report in the next popup."
        />
        <div v-if="!loading">
          <div v-if="showStartupSearch" class="row">
            <div class="col-12 col-md-12">
              <div v-if="selectedStartupObj.id" class="selected-container">
                <pic
                  class="selected-picture"
                  v-if="!loading"
                  :type="'profile-picture'"
                  :fluid="true"
                  :src="selectedStartupObj.profile_image"
                ></pic>
                <span class="selected-name">{{ selectedStartupObj.name }}</span>
              </div>
              <template v-if="!selectedStartupObj.id">
                <p class="text-sm--bold">Startup</p>
                <ORTSearchDropdown
                  :options="optionsStartups"
                  @input="searchStartups"
                  @chooseOption="selectStartup"
                />
              </template>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 col-md-4">
              <ORTDropdown
                label="Year"
                labelStyle="bold"
                field="year"
                :options="yearOptions"
                :value="add_body.year"
                @input="setBody"
              />
            </div>
            <div class="col-12 col-md-4">
              <ORTDropdown
                label="Period"
                labelStyle="bold"
                field="name"
                :options="options"
                :value="add_body.name"
                @input="setBody"
              />
            </div>
            <div class="col-12 col-md-4">
              <ORTDropdown
                label="Language"
                labelStyle="bold"
                field="language"
                :options="langOptions"
                :value="add_body.language"
                @input="setBody"
              />
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6">
              <ORTButton
                text="Cancel"
                variant="secondary"
                @click="$emit('close')"
                width="100%"
              />
            </div>
            <div class="col-6">
              <ORTButton
                v-if="reportToEdit"
                :disabled="!add_body.year || !add_body.name || !add_body.language || !selectedStartupObj.id"
                text="Update"
                @click="editReport"
                width="100%"
              />
              <ORTButton
                v-else
                :disabled="!add_body.year || !add_body.name || !add_body.language || !selectedStartupObj.id"
                text="Add"
                @click="addReport"
                width="100%"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { axiosCore } from '@/plugins/axios';
import { successAlert } from '@/ort-lib/utils/utils';
import ORTDropdown from '@/ort-lib/components/ORTDropdown.vue';
import ORTButton from '@/ort-lib/components/ORTButton.vue';
import ORTMessage from '@/components/ort-lib/ORTMessage.vue';
import ORTSearchDropdown from '@/components/ort-lib/ORTSearchDropdown.vue';

export default {
  name: 'AddReport',
  components: {
    ORTButton,
    ORTDropdown,
    ORTMessage,
    ORTSearchDropdown,
  },
  props: {
    showStartupSearch: {
      type: Boolean,
      default: true,
    },
    reportToEdit: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      selected: undefined,
      optionsStartups: [],
      startups: [],
      startup_query: '',
      selectedStartupObj: {},
      options: [
        { value: 'Q1', text: 'Q1' },
        { value: 'Q2', text: 'Q2' },
        { value: 'Q3', text: 'Q3' },
        { value: 'Q4', text: 'Q4' },
        { value: 'H1', text: 'H1' },
        { value: 'H2', text: 'H2' },
      ],
      langOptions: [
        { value: 'EN', text: 'English' },
        { value: 'FR', text: 'French' },
      ],
      yearOptions: [],
      loading: false,
      add_body: {},
    };
  },
  methods: {
    ...mapActions(['addNewReport']),
    setBody(event, field) {
      this.add_body[field] = event;
    },
    async addReport() {
      this.loading = true;
      const quarters = {
        Q1: '01-01',
        Q2: '04-01',
        Q3: '07-01',
        Q4: '10-01',
        H1: '04-01',
        H2: '10-01',
      };
      const body = {
        ...this.add_body,
        created: `${this.add_body.year}-${quarters[this.add_body.name]}`,
        startup: this.selectedStartupObj.id || this.$route.params.id,
      };
      const reportId = await this.addNewReport(body);
      this.$emit('created', {
        reportId,
        startupName: this.selectedStartupObj.name,
      });
      this.loading = false;
    },
    async editReport() {
      await axiosCore.patch(`/reportings/${this.reportToEdit.id}/`, this.add_body);
      successAlert('Report updated successfully');
      this.$emit('created', {
        reportId: this.reportToEdit.id,
        startupName: this.selectedStartupObj.name,
      });
    },
    searchStartups(val) {
      this.startup_query = val;
      this.apiGet(`startups/?fields=id,name&search=${val}&limit=10&offset=0&ordering=-is_funded`).then((response) => {
        this.startups = response.data.results;
        this.optionsStartups = this.startups.map((fundraising) => ({
          label: fundraising.name,
          id: fundraising.id,
        }));
      });
    },
    selectStartup(val) {
      this.startup_query = val.label;
      this.selectedStartupObj = this.startups.find((fund) => fund.id == val.id);
      this.loading = true;
      this.apiGet(
        `/startups/${this.selectedStartupObj.id}/?fields=profile_image`,
      ).then(
        (response) => {
          this.selectedStartupObj.profile_image = response.data.profile_image;
          this.startups = [];
          this.loading = false;
        },
        (response) => {
          this.loading = false;
        },
      );
    },
  },
  created() {
    const maxYear = (new Date()).getFullYear() + 1;
    const years = Array.from({ length: maxYear - 2015 }, (value, index) => 2016 + index);
    for (let i = 0; i < years.length; i++) {
      const obj = { text: years[i].toString(), value: years[i].toString() };
      this.yearOptions.push(obj);
    }
    if (this.reportToEdit) {
      this.selectedStartupObj = this.reportToEdit.startup;
      this.add_body = this.reportToEdit.body;
    }
  },
};
</script>

<style lang="scss" scoped>

:deep(.search-wrapper) {
  margin-top: 0;
  margin-bottom: 25px;
}

.selected-container {
  border: 1px solid $grey-color;
  border-radius: 5px;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .selected-name {
    cursor: default;
    font: $main-font-bold;
    font-size: $slarge-font-size;
    color: $black-color;
    margin-left: 20px;
  }
  .selected-picture {
    cursor: default;
    width: 50px;
    height: 50px;
    object-fit: cover;
  }
}

</style>
